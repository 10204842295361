/* eslint-disable no-unused-vars */
/* eslint-disable vue/valid-v-for */
<template>
    <div>
        <loading :active.sync="loading" 
                :can-cancel="false" 
                :is-full-page="true">
        </loading>
        <div class="comment-form" :key='compKey'>
            <b-form>
                <b-form-group label="Memory Title">
                    <b-form-input v-model="form.memory_title" placeholder="Title of post" />
                </b-form-group>
                <b-form-group label="Memory text">
                    <b-form-textarea v-model="form.memory_text" placeholder="Type here..." rows="3" />
                </b-form-group>
                <b-form-row>
                    <b-form-group label="Contact Information"/>
                </b-form-row>
                <b-form-row>
                    <b-col cols="12" lg="6">
                        <b-form-group label="First Name">
                            <b-form-input v-model="form.author_first_name" placeholder="First name" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" lg="6">
                        <b-form-group label="Last Name">
                            <b-form-input v-model="form.author_last_name" placeholder="Last name" />
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-col cols="12" lg="6">
                        <label for="Email">Email Address</label>
                        <b-input v-model="form.email" :state="valid_email" id="email-address"></b-input>
                        <b-form-invalid-feedback :state="valid_email">
                            Email address is required.
                        </b-form-invalid-feedback>
                        <b-form-valid-feedback :state="valid_email">
                            Looks good.
                        </b-form-valid-feedback>
                    </b-col>
                    <b-col cols="12" lg="6">
                        <b-form-group label="Phone">
                            <VuePhoneNumberInput v-model="form.phone" :default-country-code="'US'" :no-flags="false" />
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-group label="Street Address">
                    <b-form-input v-model="form.street_address1" placeholder="Street address" />
                </b-form-group>
                <b-form-group label="Street Address (2)">
                    <b-form-input v-model="form.street_address2" placeholder="Apt/Unit No." />
                </b-form-group>
                <b-form-row>
                    <b-col cols="12" lg="6">
                        <b-form-group label="City">
                            <b-form-input v-model="form.city" placeholder="City" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" lg="6">
                        <b-form-group label="State">
                            <b-form-input v-model="form.state" placeholder="State" />
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-col cols="12" lg="6">
                        <b-form-group label="Zipcode">
                            <b-form-input v-model="form.zipcode" placeholder="Zipcode" />
                        </b-form-group>
                    </b-col>
                </b-form-row>

                <b-form-group label="Attachments">
                    <div>
                        <b-form-radio-group
                            id="opt-media"
                            name="opt-media"
                            class="mb-2"
                            @input="handleRadio"
                            v-model="currType"
                        >
                            <b-form-radio value="photo">Photo</b-form-radio>
                            <b-form-radio value="video">Video</b-form-radio>
                        <b-button
                            type="button"
                            variant="outline-secondary"
                            @click="add_attachment()"
                            pill
                        >
                            Add another {{ currType == 'photo' ? "Photo" : "Video" }}
                        </b-button>
                        </b-form-radio-group>
                    </div>
                    <div v-for="(attachment, index) in form.attachments" :key="`attachment-${index}`" style="padding-top: 1rem; padding-bottom: 1rem;">
                        <b-form-group >
                            <b-form-file
                                style="width: 90% !important; margin-right: .5rem; vertical-align: middle;"
                                v-if="form.attachments[index].mediaType === 'photo'"
                                v-model="form.attachments[index].file"
                                :placeholder="form.attachments[index].name ? form.attachments[index].name : 'Upload Photo'"
                                :drop-placeholder="form.attachments[index].name ? form.attachments[index].name : 'Drop file here...'"
                                browse-text="Browse"
                                accept="image/*"
                                @input="(d) => addFile(d, index)"
                            ></b-form-file>
                            <b-form-input
                                style="width: 90% !important; vertical-align: middle; display: inline-block; margin-right: .5rem;"
                                v-else
                                v-model="form.attachments[index].videoUrl"
                                placeholder="Enter video url..."
                            />
                            <b-button
                                type="button"
                                variant="outline-danger"
                                @click="remove_attachment(index)"
                            >
                                X
                            </b-button>
                        </b-form-group>
                    </div>
                </b-form-group>
                <b-button
                    type="button"
                    variant="primary"
                    @click="saveData"
                    class="float-right"
                >
                    Save Memory
                </b-button>
                <b-button
                    type="button"
                    variant="secondary"
                    @click="close"
                    class="float-right mr-2"
                >
                    Cancel
                </b-button>
            </b-form>

        </div>
    </div>
</template>
<script>
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: 'MemoryForm',
    components: {
        VuePhoneNumberInput,
        Loading
    },
    props: {
        api: Object,
        person_id: String,
        value: Boolean,
    },
    data: () => ({
        compKey: 100002301321,
        savedFiles: [{ file: null, name: null }],
        form: {
            memory_title: '',
            memory_text: '',
            email: '',
            phone: '',
            street_address1: '',
            street_address2: '',
            city: '',
            state: '',
            zipcode: '',
            author_first_name: '',
            author_last_name: '',
            attachments: [{ mediaType: 'photo',
                            file: null,
                            videoUrl: '' }],
        },
        loading: false,
        currType: "photo"
        }),
    computed: {
        valid_email () {
            return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.form.email)
        },
    },
    methods: {
        add_attachment() {
            this.form.attachments.unshift({ mediaType: this.currType,
                                        file: null,
                                        videoUrl: '' })
            this.compKey += 1
        },
        remove_attachment(index) {
            if (this.form.attachments.length == 1) { this.form.attachments[0] = { mediaType: this.currType,
                                                                                file: null,
                                                                                videoUrl: '' } }
            else { this.form.attachments.splice(index,1) }
            this.compKey += 1
        },
        addFile(file, index) {
            if (!file) return
            this.form.attachments[index].name = file.name
        },
        get_request(form) {
            return {
                memory_title: form.memory_title ? form.memory_title : 'NULL',
                memory_text: form.memory_text ? form.memory_text : 'NULL',
                email: form.email ? form.email : 'NULL',
                phone: form.phone ? form.phone : 'NULL',
                street_address1: form.street_address1 ? form.street_address1 : 'NULL',
                street_address2: form.street_address2 ? form.street_address2 : 'NULL',
                city: form.city ? form.city : 'NULL',
                state: form.state ? form.state : 'NULL',
                zipcode: form.zipcode ? form.zipcode : 'NULL',
                author_first_name: form.author_first_name ? form.author_first_name : 'NULL',
                author_last_name: form.author_last_name ? form.author_last_name : 'NULL',
                person_id: this.person_id,
                type: 'new_memory'
            };
        },
        handleRadio(e) {
            if (this.form.attachments[0].file || this.form.attachments[0].videoUrl) {
                this.add_attachment()
            }
            this.form.attachments[0].mediaType = e
            this.currType = e
        },
        close() {
            this.$emit('close', this.form); // send form data
        },
        send_mail(email) {
            return this.api.make_request({ type: "send_mail", email: email });
        },
        async saveData() {
            if (!this.form.email) {
                this.$bvToast.toast('Please enter email address!', {
                    toaster: 'b-toaster-top-center',
                    variant: 'danger',
                    title: 'Error',
                    autoHideDelay: 3000
                })
                return
            }
            if (!this.form.memory_title && !this.form.memory_text && !this.form.attachments.some(a => a.file || a.videoUrl)) {
                this.$bvToast.toast('Please submit at least one of the following: Memory Text, Memory Title, or Photo/Video', {
                    toaster: 'b-toaster-top-center',
                    variant: 'danger',
                    title: 'Error',
                    autoHideDelay: 3000
                })
                return
            }
            this.loading = true
            /** upload text to db first */
            let res = await this.api.make_request(this.get_request(this.form))
            let mem_id
            try {
                mem_id = res.data.output.new_mem_post_id
            }
            catch {
                this.loading = false
                this.$bvToast.toast('Upload unsuccessful, error occured.', {
                variant: 'error',
                title: 'Error',
                autoHideDelay: 5000
                }) 
                this.close()
            }
            /** upload files and videos */
            try {
                var i
                var docRequest = { person_id: this.person_id, mem_post_id: mem_id, documents: [], type: 'new_memory_media' }
                let base_url = process.env.VUE_APP_S3_URL
                let keyy = ''
                for (i = 0; i < this.form.attachments.length; i++) {
                    if (this.form.attachments[i].mediaType == 'photo' && this.form.attachments[i].file != null) {
                        keyy = `167/People/${this.person_id}/Memory/${mem_id}/${this.form.attachments[i].file.name}`
                        let { data: { fields,
                                      url     } } = await this.api.presigned_url(keyy);
                        url = url.replace('https://s3.amazonaws.com/the-gardens-top-family-files', base_url)
                        const formData = new FormData();
                        Object.keys(fields).forEach((key) => { formData.append(key, fields[key]) })
                        formData.append('file', this.form.attachments[i].file);
                        // eslint-disable-next-line no-unused-vars
                        const data = await this.api.a.post(
                            url,
                            formData,
                            { headers: { 'Content-Type': 'multipart/form-data' } },
                        )
                        docRequest.documents.push({ document_name: this.form.attachments[i].file.name, path: base_url + `${keyy}` })
                    }
                    else if (this.form.attachments[i].mediaType == 'video' && this.form.attachments[i].videoUrl.length) {
                        docRequest.documents.push({ document_name: 'video', path: this.form.attachments[i].videoUrl })
                    }
                }
                /** update documents database */
                if (docRequest.documents.length) {
                    // eslint-disable-next-line no-unused-vars
                    let update_res = await this.api.make_request(docRequest)
                }
                /** send email confirmation */
                await this.send_mail(this.form.email)
            }
            catch(e) {
                e
            }
            this.loading = false
            this.close()
        },
    },
}
</script>
<style scoped>
.example-simple label.btn {
  margin-bottom: 0;
  margin-right: 1rem;
}
</style>