import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as VueGoogleMaps from 'gmap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'


import {
  faMap,
  faMapMarkerAlt,
  faEnvelope,
  faPhotoVideo,
  faVideo
} from '@fortawesome/free-solid-svg-icons'
import {
  faTwitter,
  faFacebook,
  faGooglePlus
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

 

library.add(
  faMap,
  faMapMarkerAlt,
  faFacebook,
  faGooglePlus,
  faTwitter,
  faEnvelope,
  faPhotoVideo,
  faVideo
  )
  
  
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCAhgWXsjOarK2RV5IYcceQNTKxYfE0dJw',
  },
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
