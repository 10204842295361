/* eslint-disable no-unreachable */
const axios = require('axios');
export default class APIClient {
  constructor(
    API_ENDPOINT_URL = process.env.VUE_APP_API_URL
  ) {
    this.base_url = API_ENDPOINT_URL;
    this.a = axios.create({
      baseURL: API_ENDPOINT_URL
    });
  }

  async make_request(request) {
    let result = await this.a.post('/get-user-media', request);
    let parsed;
    try {
      parsed = JSON.parse(result.data.body);
    } catch (err) {
      parsed = JSON.parse(JSON.stringify(result.data));
    }
    if (process.env.NODE_ENV === 'development') console.log({ type: request.type, data: parsed })
    return { type: request.type, data: parsed };
  }


  async presigned_url(key) {
    let options = {
      type: "get_url",
      key: key
    }
    return this.a.post('/get-user-media', options)
  }
    
  async uploadFile(file, key) {
    const {
      data: {
        fields,
        url
      }
    } = await this.presigned_url(key);
    const formData = new FormData();
    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key]);
    });
    formData.append('file', file);
    const data = await this.a.post(
      url,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );
    return data;
  }
  
  async version() {
    let result = await this.a.post('/get-user-media',{ type: "version_num" });
    if (process.env.NODE_ENV === 'development') console.log(result)
    return result.data.version
  }
  
  async env_vars() {
    let result = await this.a.post('/get-user-media',{ type: "env_vars" });
    if (process.env.NODE_ENV === 'development') console.log(result)
    return result.data
  }
  
  async render_html() {
    let result = await this.env_vars()
    if (result?.render_html) return result.render_html.toLowerCase() != 'false'
    return false
  }
}    